.create-admin-user {
  padding: 20px 100px 20px 100px;
  margin: 8px;
}

.create-admin-user form > div {
  margin: 0 !important;
}

.create-admin-user h1 {
  font-size: 48px;
  line-height: 48px;
  margin-top: 30px;
  font-weight: 500;
}

.create-admin-user tr td {
  padding-bottom: 2px;
}

.create-admin-user tr td, .create-admin-user {
  line-height: 25px;
  margin-bottom: 6px;
}
