html {
  // Ensure that the 1rem size is 16px and scales with the browser zoom
  // This is needed because bootstrap 3 overrides the font-size when loaded.
  //
  // It's set to 16px instead of 100% because using percentages would trigger
  // known browser bugs where elements with a font-family: monospace declaration
  // would not respect relative font-size rules.
  // The downside is that the page does not resize with the browser's font size,
  // only with the zoom level.
  font-size: 16px !important;
  letter-spacing: -0.011em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import './abstracts/mixins';

@import './base/layout-commons';
@import './base/spacing';
@import './base/style';
@import './base/typography';
@import './base/visibility-utils';
@import './base/yui-compatibility';

@import './form/checkbox';
@import './form/codemirror';
@import './form/input';
@import './form/layout';
@import './form/radio';
@import './form/reorderable-list';
@import './form/search';
@import './form/select';
@import './form/toggle-switch';

@import './modules/app-bar';
@import './modules/badges';
@import './modules/breadcrumbs';
@import './modules/buttons';
@import './modules/buttons-temp';
@import './modules/content-blocks';
@import './modules/icon-size';
@import './modules/icons';
@import './modules/manage-jenkins';
@import './modules/page-footer';
@import './modules/page-header';
@import './modules/panes-and-bigtable';
@import './modules/section';
@import './modules/side-panel-tasks';
@import './modules/side-panel-widgets';
@import './modules/spinner';
@import './modules/progress-animation';
@import './modules/table';
@import './modules/tabs';
@import './modules/tooltips';

@import './pages/about';
@import './pages/plugin-manager';
@import './pages/setupWizardFirstUser';
