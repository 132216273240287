.jenkins-section {
  border-top: 2px solid var(--panel-border-color);
  padding: var(--section-padding) 0 0 0;
  max-width: 1800px;
  margin-bottom: 24px;

  &:first-of-type {
    border-top: none;
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:empty {
    display: none;
  }

  &--bottom-padding {
    padding-bottom: var(--section-padding);
  }
}

.jenkins-section__title {
  margin: 0 0 var(--section-padding) 0;
  font-size: 1.1rem;
  font-weight: 700;
}

.jenkins-section__items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--section-padding);

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.jenkins-section__item a {
  position: relative;
  display: flex;
  z-index: 0;
  text-decoration: none;
  margin: 0;

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: -10px;
    left: -10px;
    bottom: -10px;
    right: -10px;
    z-index: -1;
    border-radius: 10px;
    transition: var(--standard-transition);
    opacity: 0;
    pointer-events: none;
  }

  &::before {
    background-color: var(--text-color);
  }

  &::after {
    box-shadow: 0 0 0 10px transparent;
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 0.1;
    }
  }

  &:active,
  &:focus {
    outline: none !important;
    z-index: 1;

    &::before {
      opacity: 0.15;
    }

    &::after {
      opacity: 0.075;
      box-shadow: 0 0 0 5px var(--text-color);
    }
  }

  dl {
    margin: 0;
    padding: 0;
    min-height: 48px;
  }

  .jenkins-section__item__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem 0 0;
    width: 48px;
    height: 48px;
    margin: 0 1rem 0 0;
    flex-shrink: 0;
    color: var(--text-color);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 100%;
      background: currentColor;
      opacity: 0.1;
      pointer-events: none;
    }

    img,
    svg {
      position: relative;
      width: 24px !important;
      height: 24px !important;
      color: currentColor;
    }

    &__badge {
      position: absolute;
      top: -4px;
      right: -4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ff0b27;
      border-radius: 100px;
      font-size: 0.7rem;
      min-height: 20px;
      min-width: 20px;
      padding: 0 0.4rem;
      box-shadow: 0 1px 1px rgba(black, 0.1);
      color: white;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(white, black);
        border-radius: 100px;
        mix-blend-mode: overlay;
        opacity: 0.35;
      }
    }
  }

  dt {
    font-size: 0.95rem;
    font-weight: 600;
    margin: 0 0 2px 0;
    color: var(--text-color);
  }

  dd {
    color: var(--text-color-secondary);
    font-weight: 500;
    margin: 0;
    line-height: 1.66;
    margin-right: 10px;
  }
}
