.jenkins-button {
  appearance: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  margin: 0;
  padding: 0.5rem 0.8rem;
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: none !important;
  background: transparent;
  color: var(--text-color) !important;
  z-index: 0;
  border-radius: 6px;
  cursor: pointer;
  min-height: 36px;
  text-shadow: 0 1px 0 var(--background);
  white-space: nowrap;
  gap: 1ch;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    opacity: 0.075;
    border-radius: inherit;
    transition: 0.2s ease;
  }

  &::before {
    background: currentColor;
  }

  &::after {
    box-shadow: inset 0 -1px 0 rgb(125, 125, 125), 0 0 0 10px transparent;
  }

  &:hover {
    &::before {
      opacity: 0.1125;
    }
  }

  &:active,
  &:focus {
    &::before {
      opacity: 0.15;
    }

    &::after {
      box-shadow: inset 0 -1px 0 rgb(125, 125, 125), 0 0 0 5px currentColor;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.jenkins-button--primary {
  color: var(--button-color--primary) !important;
  font-weight: 600;
  text-shadow: none;

  &::before,
  &::after {
    color: var(--primary);
  }

  &::before {
    opacity: 1;
  }

  &::after {
    opacity: 0.2;
  }

  &:hover {
    &::before {
      opacity: 0.9;
    }
  }

  &:active,
  &:focus {
    &::before {
      opacity: 0.8;
    }
  }
}

.jenkins-button--transparent {
  &::before {
    opacity: 0;
  }

  &::after {
    color: transparent;
    box-shadow: inset 0 -1px 0 transparent, 0 0 0 10px transparent;
  }

  &:hover,
  &:active,
  &:focus {
    &::after {
      color: currentColor;
    }
  }
}

@variants: {
  destructive: var(--red);
}

each(@variants, {
  .jenkins-button--@{key} {
    color: @value !important;

    &::after {
      background: rgba(125, 125, 125, 0.25);
      opacity: 0.1;
    }
  }
});
