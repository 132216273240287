/**
 * Backport of the YUI CSS for the autocomplete dropdowns used in comboboxes
 *
 * This code was included in the skin.css file that is no longer used since
 * https://github.com/jenkinsci/jenkins/commit/d1cd03f48103f5624790b15335eaf6ac04fdb6ad
 */
.yui-skin-sam .yui-ac {
  position: relative;
  font-size: 100%;

  // Removed a font-size: arial declaration that was present on the skin.css file
  // for this selector
}

.yui-skin-sam .yui-ac-container {
  position: absolute;
  top: 1.6em;
  width: 100%;
}

.yui-skin-sam .yui-ac-content {
  position: absolute;
  width: 100%;
  border: 1px solid #808080;
  background: #fff;
  overflow: hidden;
  z-index: 9050;
}

.yui-skin-sam .yui-ac-shadow {
  position: absolute;
  margin: 0.3em;
  width: 100%;
  background: #000;
  -moz-opacity: 0.1;
  opacity: 0.1;
  filter: alpha(opacity=10);
  z-index: 9049;
}

.yui-skin-sam .yui-ac iframe {
  opacity: 0;
  filter: alpha(opacity=0);
  padding-right: 0.3em;
  padding-bottom: 0.3em;
}

.yui-skin-sam .yui-ac-content ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.yui-skin-sam .yui-ac-content li {
  margin: 0;
  padding: 2px 5px;
  cursor: default;
  white-space: nowrap;
  list-style: none;
  zoom: 1;
}

.yui-skin-sam .yui-ac-content li.yui-ac-prehighlight {
  background: var(--auto-complete-bg-color--prehighlight);
}

.yui-skin-sam .yui-ac-content li.yui-ac-highlight {
  background: #426fd9;
  color: #fff;
}

#jenkins.yui-skin-sam {
  .yui-menu ul {
    border-color: var(--input-border);
  }

  .yuimenuitem-selected {
    background-color: var(--menu-selected-color);
  }

  .yuimenuitemlabel {
    color: var(--menu-text-color);
    font-weight: normal;
    // Done to align icons
    display: flex;
    align-items: center;

    &:visited {
      color: var(--menu-text-color);
    }

    // Disabled items
    &.yuimenuitemlabel-disabled {
      cursor: default;
      color: #A6A6A6;
    }

    // Align icons and text within
    span {
      display: inline-block;
    }
  }

  .yuimenu {
    z-index: 1000 !important;

    .bd {
      border-color: var(--input-border);
      background-color: var(--menu-bg-color);
    }
  }
}

/* Overrides for treeview-skin.css */
.ygtvitem {
  .ygtvlabel,
  .ygtvlabel:link,
  .ygtvlabel:visited,
  .ygtvlabel:hover {
    color: inherit;
    background-color: inherit;
  }
}

.ygtvfocus {
  background-color: var(--panel-header-bg-color, #c0e0e0) !important;
}

.ygtvfocus .ygtvlabel,
.ygtvfocus .ygtvlabel:link,
.ygtvfocus .ygtvlabel:visited,
.ygtvfocus .ygtvlabel:hover {
  background-color: var(--panel-header-bg-color, #c0e0e0) !important;
}

div.yahooTree td {
  vertical-align: middle;
}

.yui-tt {
  border: 1px solid black !important;
  background-color: #fff !important;
  padding: 2px !important;
  font-family: inherit !important;
  color: inherit !important;
}

.yui-skin-sam .yui-tt .bd {
  border: none !important;
  background-color: #fff !important;
}

.yuimenu li .yui-menu-tooltip {
  color: #a6a6a6;
}

.yuimenu li.yui-menuitem-tooltip {
  border-bottom: 1px solid #808080;
  padding-bottom: 3px;
  margin-bottom: 1em;
}

#jenkins .yuimenuitem {
  font-size: var(--font-size-xs);
  padding: 3px;

  svg,
  img {
    width: 1.2rem !important;
    height: 1.2rem !important;
    margin: 0.25rem 0.5rem 0.25rem 0;
  }
}

#jenkins .yuimenuitem,
#jenkins .yuimenuitemlabel {
  cursor: pointer;
}

#jenkins .yuimenu .bd {
  border: 1px solid #ccc;
  box-shadow: var(--menu-box-shadow);
}

#jenkins .yui-menu-shadow {
  display: none;
}

#jenkins .yui-ac-content {
  width: 30em;
  background-color: var(--search-bg);
  overflow: hidden;

  // TODO:  chose a better box shadow
  box-shadow: var(--search-box-shadow);
  border: none;
  border-radius: var(--header-item-border-radius);
}

#jenkins li.yui-ac-highlight {
  background: var(--search-box-completion-bg);
  font-weight: bold;
}

#jenkins.yui-skin-sam .yuimenuitem.yuimenuitem-hassubmenu {
  background-image: none; //Do not use background image, use CSS arrow instead

  //Center the CSS arrow
  position: relative;
  align-items: center;
  justify-content: center;

  &::after {
    /* Right arrow */
    content: "";
    border-left: 0.35em solid #333;
    border-left: 0.35em solid var(--text-color);
    border-top: 0.35em solid transparent;
    border-bottom: 0.35em solid transparent;
    border-right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75em;
    display: inline-block;
  }
}

.yui-panel,
.yui-panel .bd {
  background-color: var(--background) !important;
}
