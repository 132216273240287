#tasks,
.subtasks {
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0 1.2rem 2rem;
  gap: 5px;
}

.subtasks {
  margin-top: 5px;
  margin-bottom: 0;

  &:empty {
    display: none;
  }
}

#tasks .task {
  margin: 0 0 0 -0.8rem;
}

#tasks .task .task-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.55rem 0.8rem;
  gap: 0.75rem;
  cursor: pointer;
  z-index: 0;
  font-weight: 500 !important;
  font-size: 0.9rem;
  color: var(--text-color) !important;
  background: transparent;
  outline: none;
  border: none;
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--text-color);
    border-radius: 10px;
    opacity: 0;
    transition: 0.2s ease;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 10px;
    opacity: 0.05;
    transition: 0.2s ease;
    box-shadow: 0 0 0 10px transparent;
  }

  &:hover {
    &::before {
      opacity: 0.05;
    }
  }

  .task-icon-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 1.4rem;
    height: 1.4rem;

    svg,
    img {
      width: 100% !important;
      height: 100% !important;
      color: var(--text-color);

      * {
        transition: stroke-width var(--standard-transition);
      }
    }
  }

  &:active,
  &:focus {
    &::before {
      opacity: 0.1;
    }

    &::after {
      box-shadow: 0 0 0 5px var(--text-color);
    }
  }

  &--active {
    font-weight: 600 !important;

    svg * {
      stroke-width: 38px;
    }

    &::before {
      opacity: 0.1 !important;
    }
  }
}
